* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.NewApplication {
    padding-top: 25px;  
}

.NewApplication h4 {
    font-size: 13pt;
    text-decoration: none!important;
    text-align: center;
    letter-spacing: 6pt;
    color: black;
    opacity: 0.85;
    font-weight: bold;
    font-family: 'calibri';
    text-transform: uppercase
    ;
}

.application-detail h5{
   font-family: 'calibri';  
   text-align: justify;
   font-weight: 600;
}

.application-detail h6{
    font-family: 'calibri';
    text-align: justify;
    font-weight: 600;
 }



.NewApplication p {
    text-align: center;
    color: black;
    padding-top: 4px;
    font-size: 10.5pt;
    text-transform: capitalize;
    font-family: 'calibri';
    letter-spacing: 0.75pt;
    text-align: center;
}

.NewApplication img {
    height: 290px;
    width: 100%;
    margin-bottom: 10px;
    margin-top: 10px;
    text-decoration: none!important;
}

.NewApplication h3 :hover{
    color: black;
    text-decoration: none;


}

.NewApplication h3 {
    font-size: 13pt;
    font-weight: 700;
    letter-spacing: 6pt;
    opacity: .85;
    text-transform: uppercase;
    color: #000;
    font-family: calibri;
    text-align: center;
}

.gif img {
    padding-top: 55px;
    width: 100%;
    margin-bottom: 5%;
}

.application-strengths {
    height: auto;
    background-color: rgb(248, 245, 245);
}

.appli-strength h1 {
    text-align: center;
    margin-top: 3%;
    font-size: 13pt;
    opacity: 0.85 !important;
    letter-spacing: 6pt;
    text-transform: uppercase;
    color: #000;
    font-weight: bold;
    font-family: 'Calibri';
}

.application-strengths h2 {
    font-family: 'Calibri';
    font-size: 13pt;
    font-weight: bold;
    color: black;
    text-align: justify;
    letter-spacing: 0.75pt;
    padding-left: 10%;
    margin-top: 5%;
    text-transform: uppercase;
}

.application-strengths h4 {
    font-family: 'Calibri';
    font-size: 10.5pt;
    font-weight: 400;
    opacity:0.85 !important;
    color: black;
    text-align: justify;
    letter-spacing: 0.75pt;
    padding-left: 10%;
}

.application-strengths h3 {
    font-family: 'Calibri';
    font-size: 10.5pt;
    font-weight:400;
    opacity: 0.85 !important;
    color: black;
    text-align: justify;
    letter-spacing: 1.5pt;
    padding-left: 10%;
    margin-top: 5%;
}

.application-strengths h5 {
    font-family: 'Calibri';
    font-size: 10.5pt;
    font-weight:400;
    opacity: 0.85 !important;
    color: black;
    text-align: justify;
    letter-spacing: 1.5pt;
    margin-top: 5%;

}

.appLink a{
    text-decoration: none!important;
}