*{
    margin: 0%;
    padding: 0%;
    box-sizing: border-box;
}
.table{
    display: table-row;
    border: 1px solid black;
    text-indent: initial;
    border-spacing: 2px;
    border-color: gray;
}
td{
        display: table-cell;
        vertical-align: inherit;

}

.directors img{
            margin-bottom: 25px;
            display: block;
            margin: auto;
}

.data{
    width: 100%;
    height: auto;
}
.data img{
   width: 100%!important;
    /* margin-left: 35%; */
}
.research p{
    margin-top: 0 !important;
    font-family: 'Calibri';
    font-size: 10.5pt;
    color: black;
    outline-width: 1.4pt;
    letter-spacing: 0.75pt;
    opacity: 0.85;
    text-align: justify;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}
.research h3{
    text-align: center;
    margin-top: 3%;
    font-size: 13pt;
    opacity: 0.85 !important;
    letter-spacing: 6pt;
    text-transform: uppercase;
    color: #000;
    font-weight: bold;
    font-family: 'Calibri';
}
.strength h2{
    margin-top: 5%;
    text-align: center;
    font-size: 19px;
    line-height: 30px;
    letter-spacing: 8px;
    color: black;
    font-weight: bold;
    font-family: 'Corbert-Bold'
}

.para h3{
    text-transform: uppercase;
    font-weight: 600;
    color: black;
    font-size: 17px;
    font-family: 'Calibri';
    letter-spacing: 6px;
    text-align: center;
}

.chairman img{
    height: auto;
    max-width: 100%;
    border: none;
    margin-bottom: 25px;
    display: block;
    margin: auto;
    margin-bottom: 25px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4);
    /* margin-left: 15%; */
}
.para{
    padding-top: 25px;
    height: 100%;
    width: 100%;
}

.mission-border{
    border-right: 2px solid #e5e5e5; ;
}   

.para p{
    line-height: 24px;
    color: #373435;
    font-size: 13pt;
    font-family: 'calbri' !important;
    line-height: 28px;
    margin-right: 15%;
    margin-block-start: 1em;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    text-align: justify;
}
.para h6{
    font-weight: 500;
    letter-spacing: 1pt;
    margin-top: 5%;
    font-family: "calibri"!important;
    text-align: justify;
}
.para h3{
    text-transform: uppercase;
    font-weight:400;
    margin-top: 5%;
    font-family: 'calibri' !important;
    color: black;
    text-align: left;
}

.mission {
    margin-left: 13% !important;
    margin-top: 10% !important;
}

.mission img {
    margin-left: 5%;
    max-width: 100%;
    height: auto;
}

.mission h3 {
    margin-left: 7%;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 8px;
    font-weight: bold;
    font-family: 'calibri'!important;
}

.mission p {
    letter-spacing: 1px;
    text-align: justify;
    line-height: 24px;
    color: #373435;
    font-size: 14px;
    font-family: 'calibri'!important;
}

.latest-blog-img{
    width: 80%;
    height: auto;
}

.c {
    margin-left: 15%;
}

.para h3 {
    font-size: 17px;
    line-height: 30px;
    letter-spacing: 8px;
    font-weight: bold;
    font-family: 'calibri'!important;
}

.para p {
    letter-spacing: 1px;
    text-align: justify;
    line-height: 24px;
    color: #373435;
    font-size: 14px;
    font-family: 'calibri'!important;
    font-weight: 600;
}

.values {
    text-align: center;
    font-size: 17px;
    line-height: 30px;
    letter-spacing: 8px;
    font-weight: bold;
    font-family: 'Corbert-Medium', sans-serif;
}


.many{
    justify-content: center;
}

.many img {
    width: 100%;
    height: auto;
    display: block;
    margin: auto;
    border-right: 1px solid #e5e5e5;
    

}

.many h5{
    text-align: center;
    color: #373435;
    font-size: 20px;
    font-family: 'Calibri';
    font-size: 13pt;
    text-transform: capitalize;
}

.line {
    border-right: 3px solid #d1d3d487;

    margin-bottom: 15px !important;
    height: 1px;
}



.header {
    width: 100%;
    height: 570px;
    margin-top: 8% !important;
    background-color: #f5f5f5;
}

.head2 button {
    width: 25%;
    height: 35px;
    border: 1px solid grey;
}

.profile{
    height: 70px;
    background-color: #f5f5f5;
}

.cp{
    height: 100%;
}
.cp img{
    width: 100%;
    height: 380px;
}
.cp h3{
    font-family: 'Calibri' !important;
    font-size: 13pt !important;
    color: black !important;
    font-weight: 400;
    letter-spacing: 6pt !important; 
    text-align: justify;
}
.cp p{
    font-family: 'Calibri' !important;
    font-size: 10.5pt !important;
    color: black !important;
    font-weight: 400 !important;
    opacity: 0.85;
    letter-spacing: 0.75pt !important;
    text-align: justify;

}


@media only screen and (max-width: 500px) {
    .mission .latest-blog-img{
        justify-content: end !important;
    }
    .vision .latest-blog-img{
        justify-content: start !important;
    }

}
