@media only screen and (max-width: 1025px) {
  .footer {
    padding: 8px 21px;
  }
}

.footer {
  padding: 0px;
}

.logo img {
  height: 65px;
}

@media only screen and (max-width: 500px){
  .logo img {
    height: 50px;
  }
} 

.address :first-child {
  padding-top: 30px;
}

.address h4 {
  font-size: 14px;
  font-weight: 300;
  color: #373635;
  font-family: "calibri";
  font-weight: 300;
}

.address h4 span {
  font-weight: 600;
}

.address a {
  color: #373635;
}

.qr-code {
  margin-top: 28px;
}

.qr-code img {
  height: 80px;
  width: 80px;
}

.quicklink {
  padding-top: 
  48px;
}

.quicklink h4 {
  text-transform: uppercase;
  font-size: 14px;
  color: #676767;
  font-weight: 400;
}

.footer-products {
  margin-bottom: 24px;
}

.footer-products h4 {
  text-transform: uppercase;
  font-size: 14px;
  color: #676767;
  font-weight: 400;
}

.footer-products h2 {
  text-align: justify;
  font-size: 2.5em;
  line-height: 1.2;
  font-weight: 700;

}

.btnspan {
  font-size: 13px;
  color: #373635;
  font-weight: bold;
  letter-spacing: 1px;
  text-decoration: none;
}

.btnspan :hover {
  color: #666;
  text-decoration: underline;
}

.btnspan span {
  text-align: center;
  border: 1px solid #555;
  padding: 8px 15px;
}

.link {
  text-transform: uppercase;
  text-decoration: none;
  color: black;
  word-spacing: 5px;
}

.enquiry a {
  font-size: 13px;
  /* color: #373635; */
  font-weight: 600;
  letter-spacing: 1px;
}

.enquiry :hover {
  text-decoration: underline;
  color: black;
}

.tile a {
  font-size: 13px;

  font-weight: 600;
  letter-spacing: 1px;
}

.tile :hover {
  text-decoration: underline;
  color: black;
}

.icon {
  padding-bottom: 20px;
}

.icons ul {
  text-align: left;
  padding: 0px;
}

.icons ul li {
  display: inline-block;
  padding-left: 8px;
}

.icons ul li a {
  background: #808080;
  display: block;
  width: 30px;
  height: 30px;
  color: #fff;
  line-height: 28px;
  text-align: center;
  border-radius: 100%;
}

.certificate img {
  width: 96%;
  float: right;
  height: auto;
}

@media only screen and (max-width: 1025px) {
  .certificate {
    margin: 0px -12px;
  }
  .certificate img {
    float: none;
  }
  .icons ul {
    justify-content: center;
    display: flex;
  }
}

.footer2 {
  background: #000;
  padding-bottom: 30px;
  padding-top: 30px;
  padding-left: 40px;
  padding-right: 40px;
}

.footer2 .first {
  color: #f5f5f5;
  font-size: 12px;
  letter-spacing: 1px;
  padding: 0px;
}

.footer2 .first a {
  color: #f5f5f5;
}
.footer2 .last {
  color: #f5f5f5;
  font-size: 12px;
  letter-spacing: 1px;
}

.footer2 .last a {
  color: #f5f5f5;
}

@media only screen and (max-width: 1025px) {
  .footer2 .first {
    text-align: center;
    margin-bottom: 20px;
  }
  .footer2 .last {
    text-align: center;
  }
}
