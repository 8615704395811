.dropdownF{
    padding-top: 25px;
}
.form-control{
    font-size: 14px;
    color: #999999;
    height: 50px;
    border-radius: 1px !important ;
    /* border:1px solid black!important; */
}
textarea.form-control {
    padding: 10px;
    height: 178px;
}

.time{
    height: 80px!important;
}
.submit :hover{
      background-color: white !important;
      color: black !important;
      transition: 0.6s!important;
      
}
.onSubmit{
    text-transform: uppercase;
    letter-spacing: 5px;
}
.submit{
    background-color: #000000;
    color: white;
}
.submit :hover{
    background-color: #000000;
    color: white;
    
}

.contact{
    
    margin-bottom: 30px;
    padding-bottom: 30px;
}

.contact ul li .contact-icon{
    float: left; margin-right: 4px;
}

.contact-icon img{
    height: auto;
    max-width: 100%;
    border: none;
}

.contact-content{
    margin-left: 50px;
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 25px;
}

.contact-content a {
    color: #373635;
}

.contact-content h3{
    font-size: 18px;
    margin-bottom: 0;
    font-family:' Calibri';
}

.contact-content h4{
    font-weight: bolder;
    font-family: 'calibri';
}



.contact-content p{
    margin: 0px;
    font-size: 14px;
    color: #333333;
    font-weight: 300;
    margin-bottom: 25px;
    font-family: 'Calibri';
}

.map iframe{
   height: 380px;
   width: 100%;
}

.enquiryForm input{
    font-size: 14px;
    color: #999999;
    height: 50px;
    border-radius: 0;
}


.enquiryForm textarea{
    font-size: 14px;
    color: #999999;
    height: 25pxpx;
    border-radius: 0!important;
}


.enquiryForm .css-1fdsijx-ValueContainer{
    font-size: 14px;
    color: #999999;
    height: 50px;
    border-radius: 0!important;

}


.onSubmit button{
    color: #fff;
    background: #000;
    border: 1px solid #808080;
    height: 50px;
    width: 165px;
    letter-spacing: 5px;
    border-radius: 0;
    font-weight: lighter;
    font-family: inherit;
    font-size: inherit;
}

.onSubmit button:hover{
    background-color:#fff !important;
    color: black!important;
    border: black 1px solid !important;
    font-family: inherit;
    font-size: inherit;
}