.product-title img {
    height: 390px;
    width: 100%;
    display: block;
    margin-top: 3% !important;

}

.product-title h1 {
    font-family: 'Calibri';
    font-size: 13.5pt;
    color: black;
    font-weight: bold;
    letter-spacing: 1.9pt;
    margin-top: 8%;
    text-transform: uppercase;
}

.product-title p {
    font-family: 'Calibri';
    font-size: 11pt;
    color: black;
    font-weight: 400 !important;
    letter-spacing: 1.8pt;
    text-align: justify;
    margin-top: 5%;
}

.sio p {
    width: 50%;
    border: 2px solid lightgrey;
    padding: 32px 55px;
    text-align: center;
    border-radius: 10px;
    display: table-cell;
}

.icons-container img {
    height: 80px;
    width: 80px;
    margin: auto;
    display: block;
}

.icons-container p {
    text-align: center;
    color: #373435;
    font-size: 20px;
}

.icons-container h4 {
    text-align: center;
    color: #373435;
    font-size: 20px;
    font-family: 'Calibri';
    font-size: 10.5pt;
    text-transform: capitalize;
}

.pProperty h2 {
    font-family: 'Calibri';
    font-size: 10pt;
    color: black;
    font-weight: bold;
    letter-spacing: 6pt;
    text-align: center;
    text-transform: uppercase;

}

.pProperty p {
    font-family: 'Calibri';
    font-size: 10.5pt;
    color: black;
    outline-width: 1.4pt;
    letter-spacing: 1.8pt;
    text-align: center;
    padding-top: 2px;

}

.image-overlay {
    opacity: 75% !important;
    background-color: black;
    transition: opacity 0.4s ease-in-out !important;
}

.image-wrapper {
    width: 100%;
    height: 250px;
}

.image-wrapper h3 {
    font-family: arial, light !important;
    font-size: 10pt !important;
    color: black !important;
    font-weight: 400 !important;
    letter-spacing: 1.5pt !important;
    text-align: center;
    margin-top: 5px !important;
}

.product-application h2 {

    font-family: 'Calibri';
    font-size: 10pt;
    color: black;
    font-weight: bold;
    outline-width: 1/4pt !important;
    letter-spacing: 6pt !important;
    text-align: center;
    text-transform: uppercase;
}

.product-application p {

    font-family: 'Calibri' !important;
    font-size: 10pt !important;
    color: black !important;
    outline-width: 1/4pt !important;
    letter-spacing: 6pt !important;

}

.icons-container {
    justify-content: space-around;
}

.porto-wrap-container h2 {
    font-size: 2.5em;
    line-height: 1.2;
    text-align: center;
    font-weight: 700;
    margin-top: 60px;
}

.hover-text{
    text-align: justify!important;
    text-align: center!important;
    
}
.hover-text p{
    font-size: 15px!important;
    margin-right: 10px!important;
    width: auto!important;
    font-family: "calibri";
}

.porto-wrap-container p {
    margin-left: 0px !important;
    font-size: 1.4em;
    line-height: 1.7;
    text-align: center;
    font-family: Playfair Display, sans-serif !important;
}