/* Used to set css fot application details page */

.application-detail h3{
    font-family: 'Calibri' !important;
    font-size: 13pt !important;
    color: black !important;
    font-weight: 400;
    letter-spacing: 6pt !important; 
    text-align: justify;

    
}



.application-detail ul li{
    font-family: 'Calibri';
    font-size: 10.5pt;
    color: black ;
    font-weight: 600;
    letter-spacing: 0.75pt;
    text-align: justify;
    list-style-type: disc;
    margin-right: 20px;

   
}

.application-detail img{
    width: 100%;
    height: auto;
    margin-bottom: 30px;
}

.application-detail p{
    font-family: 'Calibri' !important;
    font-size: 10.5pt !important;
    color: black !important;
    font-weight: 400 !important;
    opacity: 0.85;
    letter-spacing: 0.75pt !important;
    text-align: justify;
    margin-bottom: 30px;
}