* {
  box-sizing: border-box;
  outline: none !important;
}

body,
html {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  line-height: 24px;
  color: #373435;
  font-size: 14px;
  font-family: "Corbert-Medium", sans-serif;
  letter-spacing: 1px;

  letter-spacing: 0px;
  position: relative;
  overflow-x: hidden;
}

.header-logo {
  max-width: 250px;
  height: auto;
  margin-bottom: 10px;
}

@media only screen and (max-width: 500px) {
  .header-logo {
    max-width: 100px;
    height: 60px;
    margin-bottom: 10px;
  }
}

.breadcrumb li {
  margin-left: 50%;
}

.ok {
  text-decoration: none;
  color: black;
}

.header-submenu {
  color: "#fff" !important;
  font-size: "10px" !important;
  text-transform: "capitalize" !important
}

.video-container {
  margin-top: 20px;
  overflow: hidden;
  box-sizing: border-box;
  border: none;
}

.video {
  width: 100%;
  height: auto;
}



.carousel {
  height: auto !important;
  width: auto !important;
}

/* .carousel img{
  width: 100%;
  height: auto;
} */
.dasmeshCraousel img {
  width: 100%;
  height: 700px;
}

.responsive-video {
  width: 100%;
  height: auto;
  outline: none;
  box-sizing: border-box;
  border: none;
}

.navbarMenu {
  padding: 15px 15px 0px 15px;
}

.navResponsive {
  display: none;
}

.navfirstChild {
  width: 70%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.navfirstChild li {
  background-color: white !important;
  color: black !important;
}

.navfirstChild li span:hover::before {
  width: 100%;
}

.navfirstChild li span {
  position: relative;
}

.navfirstChild li span::before {
  position: absolute;
  content: "";
  width: 0%;
  transition: width 0.5s;
  height: 2px;
  background: #000;
  bottom: 13px;
  margin: auto;
}

.navlastChild li {
  background-color: white !important;
}

.navlastChild {
  width: 30%;
  display: flex;
  justify-content: start;
}

@media only screen and (max-width: 1024px) {
  .navfirstChild {
    display: none;
  }

  .navResponsive {
    width: 65%;
    display: flex;
    /* justify-content: space-evenly; */
    /* align-items: center; */
    padding-left: 15px;
    flex-direction: column;
  }

  .mobileNavbar {
    display: flex;
    flex-direction: column;
  }

  .mobileNavbar li {
    background-color: white !important;
    color: black !important;
  }

  .hamburger svg {
    width: 26px !important;
    height: 38px !important;
  }
}

@media only screen and (max-width: 500px) {
  .hamburger {
    margin-left: -5px;
    margin-right: -5px;
    margin-top: -2px;
    margin-bottom: -2px;
  }

  .hamburger svg {
    width: 21px !important;
    height: 31px !important;
  }
}

.headerlayout div ul {
  /* margin-bottom: 15px; */
}

.headerlayout div ul li {
  /* margin-top: 15px; */
  text-transform: uppercase;
  font-weight: bold;
}

.headerlayout div ul li a {
  /* text-decoration: none; */
  color: #000000;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  position: relative;
  letter-spacing: 1.5px;
  text-decoration: none;

  align-items: center;
  display: flex;
}

/* .headerlayout div ul li a::before{
  content: '';
    display: block;
    position: absolute;
    bottom: 2px;
    left: 0;
    height: 2px;
    width: 100%;
    background-color: #000 !important;
    transform-origin: right top;
    transform: scale(0, 1);
    transition: color 0.1s,transform 0.2s ease-out;
} */

.navbarDropdown {
  /* background-color: rgb(0 0 0 / 70%); */
}

.ant-dropdown-menu {
  padding: 20px 40px !important;
  background-color: rgb(0 0 0 / 70%);
}

.ant-dropdown-menu-item {
  border-radius: 0px !important;
  background-color: rgb(0 0 0 / 70%) !important;
}

.navbarDropdown ul {
  width: 200px;
  padding: 0px !important;

}

.navbarDropdown li {
  position: relative;
  background-color: rgb(0 0 0 / 70%);
  border-radius: 0px;
}

.navbarDropdown li a:hover::before {
  width: 100%;
}

.navbarDropdown li a::before {
  position: absolute;
  content: "";
  width: 0%;
  transition: width 0.5s;
  height: 1px;
  background: white;
  bottom: -3px;
}

.navbarDropdown li a {
  text-decoration: none;
  /* color: white !important; */
  font-size: 12px;
  font-weight: 600;
  text-transform: capitalize;
  position: relative;
  letter-spacing: 1.5px;
  color: white !important;
}

.anticon svg {
  width: 10px;
}

.ant-layout-content {
  background-color: #fff;
}

/* .products img {
  max-height: 85px;
  right: 5%;
  position: absolute;
  margin-top: -15px;
} */

.porto-wrap-container img {
  height: 100px;
  width: 100px;
}


.product-content {
  padding-top: 0;
}

.post-content {
  padding-top: 5%;
}

.product-content p {
  text-align: justify;
  margin-left: 0px !important;
}

.product-formula {
  border-color: #ebebeb;
  background-color: transparent;
  border-width: 3px;
  border-radius: 10px;
}

.inner {
  padding: 32px 55px;
  border: solid rgb(237, 236, 236);
  border-radius: 25px 25px 25px;
}

.white-background-image {
  background-color: white;
}

.boiling img {
  height: 100%;
  width: 100%;
}

.image-container {
  position: relative;
  margin: 20px;
}
/* 
.image-overlay Image{
  height: 100%;
  width: 100%;
} */

.image-wrapper {
  position: relative;
  display: inline-block;
  /* width: 100%;
  height: 100%; */
}

.image-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  /* height: 100%; */
  height: 0;
  overflow: hidden;
  transition: height 0.3s ease;
}

.image-wrapper:hover .image-overlay {
  height: 100%;
  /* padding-top: 5px; */

}

.image-text {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  color: #fff;
  padding: 10px;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
  text-align: justify;
  font-family: 'Calibri';
}

.image-overlay:hover .image-text {
  opacity: 1;
  animation: slideIn 0.3s ease;
  pointer-events: auto;
}

@keyframes slideIn {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}

.item {
  margin-top: 35px;
}

.navbar-nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 30px;
  list-style: none;
}

ul,
li,
label,
span {
  list-style: none;
  padding: 0;
  margin: 0;
}

dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

nav-item {
  margin-right: 25px;
}

.dropdown,
.dropleft,
.dropright,
.dropup {
  position: relative;
  margin-right: 30px;
}

.navbar-light .navbar-nav .nav-link {
  color: #000000;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  position: relative;
  letter-spacing: 1.5px;
}