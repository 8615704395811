* {
    margin: 0%;
    padding: 0%;
    box-sizing: border-box;
}

.v_design video {
    height: 100%;
    max-width: 100%;
    border: none;
    margin-top: -63px;
}

.discover-heading h3{
    text-align: center;
    margin-top: 3%;
    font-size: 13pt;
    opacity: 0.85 !important;
    letter-spacing: 6pt;
    text-transform: uppercase;
    color: #000;
    font-weight: bold;
    font-family: 'Calibri';
}

.discover-heading p{
    font-family: 'Calibri';
    font-size: 10.5pt;
    color: black;
    outline-width: 1.4pt;
    letter-spacing: 0.75pt;
    opacity: 0.85;
    text-align: center;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    margin-bottom: 3%;
}

.discover{
    background-color: rgb(248, 245, 245);
    /* background-color: (248, 245, 245); */
}

.discover img {
    margin-top: 5%;
    /* margin-left: 15%; */
    width: 100%;
    height: 350px;
}

.discover h3 {
    text-align: center;
    margin-top: 3% !important;
    font-family: 'Calibri';
    font-size: 11pt;
    opacity: 0.85 !important;
    font-weight: bold;
    color: black;
    letter-spacing: 1.8pt;
    text-transform: uppercase;
}

.discover h2{
    text-align: left;
    margin-top: 3% !important;
    font-family: 'Calibri';
    font-size: 13pt;
    opacity: 0.85 !important;
    font-weight: bold;
    color: black;
    letter-spacing: 1.8pt;
    text-transform: uppercase;
}


.discover p {

    font-family: 'Calibri';
    font-size: 10.5pt;
    color: black;
    outline-width: 1.4pt;
    letter-spacing: 0.75pt;
    opacity: 0.85;
    text-align: justify;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;

}

.driven-content {
    margin-top: 15%;
}

.driven-content p{
    font-family: 'Calibri';
    font-size: 10.5pt;
    color: black;
    outline-width: 1.4pt;
    letter-spacing: 0.75pt;
    opacity: 0.85;
    text-align: justify;
    margin-left: 25px;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.home-products img {
    /* display: block;
    justify-self: center; */
    height: auto;
    width: 100%;
    box-shadow: rgba(182, 182, 185, 0.592) 0px 7px 29px 0px;
}

.home-products p{
    font-family: "calibri";
    text-transform: uppercase;
    font-weight: 600;
    color: black;
    letter-spacing: 6px;
    text-align: justify !important;
    margin-top: 8px;

}

.infrastructure{
    background-color: rgb(248, 245, 245);
    height: auto;
}

.collage img {
    width: 100%;
    height: auto;
}


.subscribe {
    margin-top: 5%;
    text-align: center;
}

.subscribe h3 {
    font-size: 32px;
    font-family: 'Calibri';
    font-size: 20px;
    color: #000000;
    font-weight: 300;
    line-height: 30px;
    margin-bottom: 10px;
}

@media(max-width: 767px) {
	.subscribe input {
        height: 40px;
	}
}

.subscribe input {
    font-size: 14px;
    color: #999999;
    height: 50px;
    width: 230px;
    border-radius: 0;
}

.subscribe button {
    width: 120px;
    height: 50px;
    margin-top: 2%;
    margin-bottom: 5%;
    background-color: black;
    color: white;
    font-size: 17px;
    letter-spacing: 2px;
    font-weight: 400;
}

.subscribe button:hover {
    background-color: white;
    color: black;
    transition: 0.5s;
}

.strengths {
    height: auto;
    background-color: rgb(248, 245, 245);
    margin-bottom: 25px;
}


.strengths h5 {
    font-family: 'Calibri';
    font-size: 10.5pt;
    font-weight:400;
    opacity: 0.85 !important;
    color: black;
    text-align: justify;
    letter-spacing: 1.5pt;
    padding-left: 10%;
    margin-top: 5%;

}

.strengths img {
    width: 100%;
    height: auto;
}

.strengths h3 {
    font-family: 'Calibri';
    font-size: 13pt;
    font-weight: bold;
    color: black;
    text-align: justify;
    letter-spacing: 0.75pt;
    padding-left: 10%;
    margin-top: 5%;
}

.strengths p {
    font-family: 'Calibri';
    font-size: 10.5pt;
    font-weight: 400;
    opacity:0.85 !important;
    color: black;
    text-align: justify;
    letter-spacing: 0.75pt;
    padding-left: 10%;
}

.bullet-image{
    height: 20px!important;
    width:20px!important;
}

.carousel{
    width: 100%;
    height:600px;
    object-fit:cover;
    overflow:hidden;
}

.dashmesh video{
    max-width: auto;
    height: 690px;
    object-fit: fill;
}


.content_heading {
    margin-top: 5%;
	font-weight: 500;
	text-transform: uppercase;
    font-family: 'calibri';
	letter-spacing: 0.95pt;
    margin-top: 30px;
    font-weight: bold;
    font-size: 20px;
}

.content_desc {
	word-spacing: 0.95pt;
    font-family: 'calibri';
	display: flex;
	padding-top: 10%;
    padding-left: 5%;
    text-align: justify;
}

.explore a{
    background-color: white;
    color: black;
    text-decoration: none;
    border-radius: 10px 10px solid;
    padding: 12px 16px;
    
}

.explore a :hover{
   color: black;
}
.gif_home {
   margin-bottom: 10%;
    padding-top: 55px;
    width: 100%;
}

@media screen and (max-width: 950px) {
	.content {
		flex-direction: column;
		width: 100%;
	}
	.content_heading {
		letter-spacing: 8px;
	}
}
